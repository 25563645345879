import { Controller } from "stimulus";
import app from '../common/utils';


import animate from '../animation/animateIn';

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    app.setup(this);
    this.pageLoad();
    this.url = window.location.href;
    this.previousUrl = '';

    // Image fix for Safari
    if (navigator.userAgent.match(/Version\/[\d.]+.*Safari/)) {
      document.addEventListener('turbo:render', () => {
        document.querySelectorAll('picture').forEach((img) => {
          img.outerHTML = img.outerHTML;
        });
      });
    }
  }

  pageLoad() {
    if (this.isFrontend()) {
      document.addEventListener('YUZU:load', (e) => {
        this.updateStyles(e);
        animate();
      }, { once: true });
    }
    else {
      document.addEventListener('turbo:load', (e) => {
        this.updateStyles(e);
        this.triggerAnalyticsPageLoad(e);
        animate();
      }, { once: true });
    }
  }

  async beforeRender(e) {
    e.preventDefault();
    this.element.style.setProperty('scroll-behavior', 'auto');

    e.detail.newBody.classList.remove('is-animating-in');
    e.detail.newBody.classList.remove('is-animating-out');
    e.detail.newBody.classList.add('is-animating-in');

    await this.animateOut();

    e.detail.resume();
    this.pageLoad();
  }

  visit(e) {
    document.documentElement.classList.toggle('is-going-back', e.detail.action === 'restore' && this.previousUrl === e.detail.url);
  }

  animateOut() {
    document.body.classList.add('is-animating-out');
    let duration = parseFloat(getComputedStyle(document.documentElement).getPropertyValue('--site-transition-duration'), 10) * 1000;
    duration += 300;

    const animationFailsafe = setTimeout(() => {
      document.body.classList.remove('is-animating-out');
    }, duration)

    return new Promise(r => {
      this.element.addEventListener('PageTransitionedOut', (e) => {
        clearTimeout(animationFailsafe);
        document.body.classList.remove('is-animating-out');
        r();
      }, { once: true });
    });
  }

  updateStyles(e) {
    const layoutRoot = document.body.querySelector('.yuzu-layout-root');
    if (layoutRoot) {
      const htmlClasses = layoutRoot.dataset.htmlClasses;
      if (htmlClasses) {
        htmlClasses.split(' ').forEach(cssClass => {
          this.element.classList.remove('page--theme-dark');
          this.element.classList.remove('page--theme-light');
          this.element.classList.add(cssClass);
        });
      }
    }
    this.element.style.setProperty('scroll-behavior', 'smooth');

    this.previousUrl = this.url;
    this.url = window.location.href;
  }

  triggerAnalyticsPageLoad(e) {

    var titleTag = document.head.querySelector('title');
    var url = new URL(e.detail.url);
    var dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({
      'event': 'pageview',     
      'page_location': url.pathname,
      'page_title': titleTag.innerText   
    });

  }
};