import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'background',
      'row',
      'svg',
      'overhang'
    ];
  }

  initialize() {
    this.svgBgWidth = this.svgTarget.viewBox.baseVal.width;
    this.svgBgHeight = this.svgTarget.viewBox.baseVal.height;

    this.overhangSvgTarget = this.overhangTarget.querySelector('svg');
    this.svgOverhangWidth = this.overhangSvgTarget.viewBox.baseVal.width;
    this.svgOverhangHeight = this.overhangSvgTarget.viewBox.baseVal.height;

    this.backgroundDuration = parseFloat(getComputedStyle(this.svgTarget).getPropertyValue('animation-duration'), 10);

    setTimeout(() => {
      this.run();
    }, 100);

    window.addEventListener('resize', () => {
      this.run();
    });
  }

  run() {
    requestAnimationFrame(() => {
      this.addRemoveBackgroundTiles();
      this.addRemoveOverhangTiles();
    });
  }

  addRemoveBackgroundTiles() {
    const bgHeight = this.backgroundTarget.offsetHeight;
    const bgWidth = this.backgroundTarget.offsetWidth;
    const requiredSVGCount = Math.ceil(bgWidth / this.svgBgWidth);
    const svgPerRowCount = this.getSvgPerRowCount();

    if (svgPerRowCount < requiredSVGCount) {
      const amountToAdd = requiredSVGCount - svgPerRowCount;
      this.addRemoveSvgsPerRow('add', amountToAdd);
    }
    else if (svgPerRowCount > requiredSVGCount) {
      const amountToRemove = svgPerRowCount - requiredSVGCount;
      this.addRemoveSvgsPerRow('remove', amountToRemove);
    }

    document.documentElement.style.setProperty('--site-header-background-duration', (this.backgroundDuration * this.getSvgPerRowCount()) + 's');

    const requiredRowCount = Math.ceil(bgHeight / this.svgBgHeight);

    if (this.rowTargets.length < requiredRowCount) {
      const amountToAddRows = requiredRowCount - this.rowTargets.length;
      for (let index = 0; index < amountToAddRows; index++) {
        let newRow = this.rowTargets[0].cloneNode(true);
        this.backgroundTarget.appendChild(newRow);
      }
    }
    else if (this.rowTargets.length > requiredRowCount) {
      const amountToRemove = this.rowTargets.length - requiredRowCount;
      for (let index = 0; index < amountToRemove; index++) {
        this.rowTargets[this.rowTargets.length - 1].remove();
      }
    }
  }

  getSvgPerRowCount() {
    return this.rowTargets[0].querySelectorAll('[data-target="site-header-background.svg"]').length;
  }

  addRemoveSvgsPerRow(action = 'add', amount) {
    this.rowTargets.forEach(row => {
      const svgs = row.querySelectorAll('[data-target="site-header-background.svg"]');

      if (action === 'add') {
        for (let index = 0; index < amount; index++) {
          let newSvg = this.svgTargets[0].cloneNode(true);
          newSvg.style.setProperty('--tile-index', svgs.length + index);
          row.appendChild(newSvg);
        }
      }
      else {
        for (let index = 0; index < amount; index++) {
          svgs[svgs.length - 1].remove();
        }
      }
    });
  }

  addRemoveOverhangTiles() {
    const bgHeight = this.backgroundTarget.offsetHeight;
    const requiredSVGCount = Math.ceil(bgHeight / this.svgOverhangHeight);
    const svgs = this.overhangTarget.querySelectorAll('svg');

    if (svgs.length < requiredSVGCount) {
      const amountToAdd = requiredSVGCount - svgs.length;
      for (let index = 0; index < amountToAdd; index++) {
        let newRow = svgs[0].cloneNode(true);
        this.overhangTarget.appendChild(newRow);
      }
    }
    else if (svgs.length > requiredSVGCount) {
      const amountToRemove = svgs.length - requiredSVGCount;
      for (let index = 0; index < amountToRemove; index++) {
        svgs[(svgs.length - 1) - index].remove();
      }
    }
  }
};