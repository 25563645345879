import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    const selector = this.element.dataset.selector;
    this.paths = this.element.querySelectorAll(selector);

    this.paths.forEach(path => {
      path.addEventListener('transitionend', (e) => {
        e.target.classList.remove('is-animating');
      });
  
    //   path.addEventListener('mouseover', (e) => {
    //     e.target.classList.add('is-animating');
    //   });      
    });

    this.element.addEventListener('mouseover', (event) =>  {
      let target = event.target;
      if(target.matches(selector)) {
        target.classList.remove('is-animating');
        target.classList.add('is-animating');
      }
    });
  }
};