import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
    ];
  }

  initialize() {
    this.nextSection = this.element.nextElementSibling;
  }

  scrollDown(e) {
    const scrollY = this.nextSection.getBoundingClientRect().top + window.scrollY;
    window.scroll({ top: scrollY, left: 0, behavior: 'smooth' });
  }
};