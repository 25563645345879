window.addEventListener('resize', () => {
    run();
});
document.addEventListener('YUZU:load', (e) => {
    run()
});
document.addEventListener('turbo:load', (e) => {
    run()
});
const run = () => {
    requestAnimationFrame(() => {
        setVh();
    });
};
const setVh = () => {
    let vh = parseFloat(window.innerHeight * 0.01).toFixed(2);
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    let vw = parseFloat(document.documentElement.clientWidth * 0.01).toFixed(2);
    document.documentElement.style.setProperty('--vw', `${vw}px`);

    if (getComputedStyle(document.body).getPropertyValue('overflow') !== 'hidden') {
        const scrollbarWidth = window.innerWidth - document.documentElement.offsetWidth;
        document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
    }
};
export default {
    run: run
};