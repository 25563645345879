import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'svg'
    ];
  }

  initialize() {
    this.svgWidth = this.svgTargets[0].viewBox.baseVal.width;
    
    window.addEventListener('resize', () => {
      this.checkSvgCount();
    });
    
    this.checkSvgCount();
  }
  
  checkSvgCount() {
    if((this.svgTargets.length * this.svgWidth) < window.innerWidth) {
      const amountNeeded = Math.ceil(window.innerWidth / this.svgWidth);
      const amountToAdd = amountNeeded - this.svgTargets.length;
      
      for (let index = 0; index < amountToAdd; index++) {
        let newSvg = this.svgTargets[0].cloneNode(true);
        this.element.appendChild(newSvg);
      }
    }
  }
};