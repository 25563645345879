import * as Turbo from "@hotwired/turbo";
import focus from "focus-visible";
import vh from "./common/vh";
import inert from "wicg-inert";

import aria from './aria/aria';
import smoothscroll from 'smoothscroll-polyfill';


import { Application } from "stimulus";
import utils from "./common/utils";
import { definitionsFromContext } from "stimulus/webpack-helpers";
// import splideArrows from './website/splide_arrows';

smoothscroll.polyfill();
const application = Application.start();
utils.setup(application);

const context = require.context('./website', true, /\.js$/);

application.load(definitionsFromContext(context));


// import blockHelper from './common/blockHelper';


document.addEventListener('turbo:load', () => {
    vh.run();
    // Misc.
    // splideArrows();
});