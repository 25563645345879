import { Controller } from "stimulus";

export default class extends Controller {

  static get targets() {
    return [
      'row',
      'column',
      'columnTile',
      'rowTile',
    ];
  }

  initialize() {
    this.tileWidth = this.columnTileTarget.viewBox.baseVal.width;
    this.tileHeight = this.columnTileTarget.viewBox.baseVal.height;
    this.tilePathCount = this.columnTileTarget.querySelectorAll('path').length;

    this.row = this.rowTileTarget.cloneNode(true);
    this.row.dataset.target = this.row.dataset.clonedTarget;
    this.row.removeAttribute('data-cloned-target');
    this.column = this.columnTileTarget.cloneNode(true);
    this.column.dataset.target = this.column.dataset.clonedTarget;
    this.column.removeAttribute('data-cloned-target');

    setTimeout(() => {
      this.run();
    }, 100);

    window.addEventListener('resize', () => {
      this.run();
    });
    this.element.addEventListener('animationend', () => {
      this.checkAnimationComplete();
    });
  }

  run() {
    requestAnimationFrame(() => {
      if (!this.element.classList.contains('animate-in')) {
        this.addRemoveBackgroundTiles();
      }
    });
  }

  checkAnimationComplete() {
    this.animatedPaths = this.animatedPaths + 1;

    if (this.animatedPaths === this.pathCount) {
      this.animatedPaths = 0;
      if (document.body.classList.contains('is-animating-out')) {
        const event = new Event('PageTransitionedOut');
        document.documentElement.dispatchEvent(event);
        document.body.classList.add('is-animating-in');
      }
      else if (document.body.classList.contains('is-animating-in')) {
        document.body.classList.remove('is-animating-out');
        document.body.classList.remove('is-animating-in');
      }
    }
  }

  addRemoveBackgroundTiles() {
    const bgHeight = this.element.offsetHeight;
    const bgWidth = this.element.offsetWidth;
    const requiredSVGCount = Math.ceil(bgWidth / this.tileWidth);
    const svgPerRowCount = this.getSvgPerRowCount();

    this.tileDuration = parseFloat(getComputedStyle(this.columnTileTarget).getPropertyValue('animation-duration'), 10);

    const requiredRowCount = Math.ceil(bgHeight / this.tileHeight);

    if (this.rowTargets.length < requiredRowCount) {
      const amountToAddRows = requiredRowCount - this.rowTargets.length;
      for (let index = 0; index < amountToAddRows; index++) {
        let newRow = this.rowTargets[0] ? this.rowTargets[0].cloneNode(true) : this.row.cloneNode(true);
        this.element.appendChild(newRow);
      }
    }
    else if (this.rowTargets.length > requiredRowCount) {
      const amountToRemove = this.rowTargets.length - requiredRowCount;
      for (let index = 0; index < amountToRemove; index++) {
        this.rowTargets[this.rowTargets.length - 1].remove();
      }
    }

    if (svgPerRowCount < requiredSVGCount) {
      const amountToAdd = requiredSVGCount - svgPerRowCount;
      this.addRemoveSvgsPerRow('add', amountToAdd);
    }
    else if (svgPerRowCount > requiredSVGCount) {
      const amountToRemove = svgPerRowCount - requiredSVGCount;
      this.addRemoveSvgsPerRow('remove', amountToRemove);
    }

    this.setDuration();

    this.pathCount = this.element.querySelectorAll('path').length - this.tilePathCount;
    this.animatedPaths = 0;
  }

  setDuration() {
    const duration = this.tileDuration * this.getSvgPerRowCount();

    document.documentElement.style.setProperty('--site-transition-duration', duration + 's');
    this.duration = duration;
  }

  getSvgPerRowCount() {
    if (!this.rowTargets.length) {
      return 0;
    }
    return this.rowTargets[0].querySelectorAll('[data-target="site-page-transition.column"]').length;
  }

  addRemoveSvgsPerRow(action = 'add', amount) {
    this.rowTargets.forEach(row => {
      const svgs = row.querySelectorAll('[data-target="site-page-transition.column"]');

      if (action === 'add') {
        for (let index = 0; index < amount; index++) {
          let newSvg = this.column.cloneNode(true);
          newSvg.style.setProperty('--tile-index', svgs.length + index);
          const type = this.weightedRandom(1, 2);
          const otherType = type === 1 ? 2 : 1;

          newSvg.querySelectorAll(`.st-${otherType}`).forEach(e => e.remove());

          newSvg.querySelectorAll('path').forEach(path => {
            path.style.setProperty('--additional-delay', this.weightedRandom(1, 4));
          });
          row.appendChild(newSvg);
        }
      }
      else {
        for (let index = 0; index < amount; index++) {
          svgs[svgs.length - 1].remove();
        }
      }
    });
  }

  weightedRandom(min, max) {
    return Math.round(max / (Math.random() * max + min));
  }
};