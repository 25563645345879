import { Controller } from "stimulus";
import app from '../common/utils';

export default class extends Controller {

  static get targets() {
    return [
      'navToggle',
    ];
  }

  initialize() {
    app.setup(this);
    this.hideClass = 'site-header-hide';
    this.showClass = 'site-header-show';

    this.utilites.addDelegatedEvent(this.element, 'click', 'a', (e) => {
      this.toggle(e);
    });
  }

  toggle(e) {
    this.navToggleTargets.forEach(toggle => {
      toggle.setAttribute('aria-expanded', toggle.getAttribute('aria-expanded') !== 'true');
    });
    this.element.classList.toggle('is-open');

    if (this.element.dataset.static != undefined) {
      window.scrollTo(0, 0);
    }

    if (e) {
      document.body.classList.toggle('is-site-nav-open');
    }

    window.requestIdleCallback(() => {
      document.querySelector('.page__main').inert = this.element.classList.contains('is-open');
      document.querySelector('.site-footer').inert = this.element.classList.contains('is-open');
      console.log(this.element.classList.contains('is-open'));
    });
  }
};