import { Controller } from "stimulus";

export default class extends Controller {

    static get targets() {
        return [
        ];
    }

    initialize() {
        this.element.addEventListener('input', () => this.updateMinHeight());
        this.textLength = 0;
        this.verticalBorders = parseInt(getComputedStyle(this.element).borderTopWidth, 10) + parseInt(getComputedStyle(this.element).borderBottomWidth, 10);
        this.updateMinHeight();
    }

    updateMinHeight() {
        const newLength = this.element.value.length;
        if(this.textLength > newLength) {
            this.element.style.height = 'auto';
        }
        this.textLength = newLength;
        this.element.style.height = (this.element.scrollHeight + this.verticalBorders) + 'px';
    }
}